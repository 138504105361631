<template>
  <footer class="iv2-footer theme__footer-bg" :style="pageStyle">
    <div class="iv2-footer__container theme__footer-text">
      <div class="iv2-footer__logo">
        <router-link :to="'/' + lang + query">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_footer +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_footer"
              class="iv2-footer__logo-img"
              :alt="organization.name + $store.state.publicLang.general.alt"
            />
          </picture>
        </router-link>
      </div>
      <div class="row">
        <div class="iv2-footer__item col-xl-6">
          <div class="d-none d-md-block">
            <p class="m-0 size-16">{{ organization.name }}</p>
            <div v-if="organization.tel_number" class="d-inline-flex">
              <span class="mt-auto mr-2">{{
                $store.state.themeLang.general.tel
              }}</span>
              <p class="mb-1 size-28" style="line-height: 1">
                {{ organization.tel_number }}
              </p>
            </div>
            <p
              v-if="organization.opening_hours"
              v-html="organization.opening_hours"
              class="size-14 mt-xl-0"
              style="line-height: 1.5"
            ></p>
            <div class="d-flex">
              <span v-if="organization.zip_code" class="m-0 size-14">{{
                "〒" + organization.zip_code
              }}</span>
              <span v-if="organization.prefecture_name" class="size-14 m-0">{{
                organization.prefecture_name
              }}</span>
              <span v-if="organization.city_name" class="size-14 m-0">{{
                organization.city_name
              }}</span>
              <span v-if="organization.town_name" class="size-14 m-0">{{
                organization.town_name
              }}</span>
              <span v-if="organization.building_name" class="size-14 m-0">{{
                organization.building_name
              }}</span>
            </div>
            <p
              v-if="organization.free_text"
              v-html="organization.free_text"
              class="size-14 m-0"
            ></p>
          </div>
          <p class="size-16">{{ information.meta_description }}</p>
        </div>
        <div class="iv2-footer__nav col-xl-6 text-left">
          <p>{{ $store.state.themeLang.general.menu }}</p>
          <ul class="iv2-footer__nav-list">
            <li
              v-for="nav in footer"
              :key="nav.id"
              class="iv2-footer__nav-item"
            >
              <router-link
                v-if="nav.link_type == 'internal'"
                :to="nav.link_href + lang + query"
                :target="nav.target_type"
                class="iv2-footer__nav-link theme__footer-link"
                >{{ nav.item_name }}</router-link
              >
              <a
                v-else-if="nav.link_type == 'external'"
                :href="nav.link_href"
                :target="nav.target_type"
                rel="noopener"
                class="iv2-footer__nav-link theme__footer-link"
                >{{ nav.item_name }}</a
              >
              <ul
                v-if="!nav.children.length == false"
                class="iv2-footer__nav-list list-unstyled"
              >
                <li
                  v-for="cNav in nav.children"
                  :key="cNav.id"
                  class="iv2-footer__nav-item"
                >
                  <router-link
                    v-if="cNav.link_type == 'internal'"
                    :to="cNav.link_href + lang + query"
                    :target="cNav.target_type"
                    class="iv2-footer__nav-link theme__footer-link"
                    >{{ cNav.item_name }}</router-link
                  >
                  <a
                    v-else-if="cNav.link_type == 'external'"
                    :href="cNav.link_href"
                    :target="cNav.target_type"
                    rel="noopener"
                    class="iv2-footer__nav-link theme__footer-link"
                    >{{ cNav.item_name }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="iv2-footer__sns my-3 d-md-none d-flex">
        <span
          v-if="
            !(
              sns.is_linked_facebook == 0 &&
              sns.is_linked_instagram == 0 &&
              sns.is_linked_twitter == 0 &&
              sns.is_linked_line == 0
            )
          "
          class="size-16 mr-3 ml-auto"
          >{{ $store.state.themeLang.general.sns }}</span
        >
        <ul class="d-flex list-unstyled p-0 mr-auto">
          <li v-if="sns.is_linked_facebook == 1">
            <a
              :href="sns.facebook_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-facebook-f"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_instagram == 1">
            <a
              :href="sns.instagram_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-instagram"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_twitter == 1">
            <a
              :href="sns.twitter_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-twitter"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_line == 1">
            <a
              :href="sns.line_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-line"></i>
            </a>
          </li>
          <!-- <li v-if="rss.is_linked_blog == 1">
            <a
              :href="rss.url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fas fa-rss"></i>
            </a>
          </li> -->
        </ul>
      </div>
      <hr class="iv2-footer__hr theme__footer-hr" />
      <div class="iv2-footer__bottom row">
        <div
          :class="{
            translation: translation.is_use == 1 && organization.copy_right,
          }"
          class="iv2-footer__bottom-item d-flex col-xl-6"
        >
          <small v-if="organization.copy_right" class="mr-xl-4">{{
            organization.copy_right
          }}</small>
          <div v-if="translation.is_use == 1" class="iv2-language">
            <button
              class="iv2-language-btn theme__footer-text d-none d-xl-block"
              @mouseover="langSelector.active = true"
            >
              <i class="fas fa-globe theme__footer-text size-12 mr-2"></i
              >{{ langSelector.select }}
            </button>
            <ul
              @mouseleave="langSelector.active = false"
              :class="{ active: langSelector.active }"
              class="iv2-language__selector theme__footer-bg list-unstyled d-none d-xl-block"
            >
              <li
                v-for="item in translation.active_language"
                :key="item.code"
                @click="changeTranslation(item.code)"
                :class="{ opacity: item.name == langSelector.select }"
                class="iv2-language__selector-list theme__footer-text"
              >
                {{ item.name }}
              </li>
            </ul>
            <button
              class="iv2-language-btn theme__footer-text d-block d-xl-none"
              @click="activeSelect"
            >
              <i class="fas fa-globe theme__footer-text size-12 mr-2"></i
              >{{ langSelector.select }}
            </button>
            <ul
              :class="{ active: langSelector.active }"
              class="iv2-language__selector theme__footer-bg list-unstyled d-block d-xl-none"
            >
              <li
                v-for="item in translation.active_language"
                :key="item.code"
                @click="changeTranslation(item.code)"
                :class="{ opacity: item.name == langSelector.select }"
                class="iv2-language__selector-list theme__footer-text"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6 d-none d-xl-block text-right">
          <small @click="scrollTop" class="iv2-footer__pagetop"
            >{{ $store.state.themeLang.general.page_top
            }}<i class="fas fa-angle-double-up ml-2"></i
          ></small>
        </div>
      </div>
    </div>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      } @media (min-width: 768px) and (max-width: 1199px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </footer>
</template>
<script>
export default {
  props: {
    footer: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    translation: Object,
    count: Number,
    theme: Object,
  },

  data() {
    return {
      query: "",
      lang: "",
      langSelector: {
        active: false,
        select: "",
      },
      pageStyle: {
        backgroundImage: "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      },
    };
  },

  created() {
    /** language初期値のセットアップ */
    var query = this.translation.active_language.filter(
      (e) => e.code == this.$route.query.hl
    );
    var cookie = this.translation.active_language.filter(
      (e) => e.code == this.$cookies.get("iv-language")
    );
    var type = this.translation.active_language.filter(
      (e) => e.code == this.translation.default_lang
    );
    if (query.length > 0) {
      this.langSelector.select = query[0].name;
    } else if (cookie.length > 0) {
      this.langSelector.select = cookie[0].name;
    } else {
      this.langSelector.select = type[0].name;
    }

    if (this.theme.footer_bg_image) {
      this.pageStyle.backgroundImage =
        "url('" + this.theme.footer_bg_image + "')";
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    activeSelect() {
      this.langSelector.active = !this.langSelector.active;
    },

    /** language変更時のcookie保存処理 */
    changeTranslation(val) {
      this.langSelector.active = false;
      // cookie保存
      this.$cookies.set("iv-language", val, "30min");

      // ページの再レンダリング
      var route = this.$route.path;
      this.$router.push({ path: route });
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },

    /** フリーページにより大きく可変するナビゲーションに対応した高さを指定 */
    setHeight() {
      let navHeight = this.count * 23;
      if (navHeight > 250) {
        return (
          ".iv2-footer .iv2-footer__container .iv2-footer__nav-list {max-height: " +
          navHeight +
          "px;}"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-footer__container {
  width: 90%;
  margin: auto;
  font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP", serif;

  .iv2-footer__logo {
    margin: 20px 0;

    .iv2-footer__logo-img {
      max-width: 300px;
      max-height: 130px;
    }
  }

  .iv2-footer__item {
    text-align: left;
  }

  .iv2-footer__nav-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 250px;
    vertical-align: top;
    margin-bottom: 30px;
    padding-left: 0px;
    margin-right: 5px;

    .iv2-footer__nav-item {
      font-size: 14px;
      padding: 5px 0;
      display: block;
      padding-right: 20px;

      .iv2-footer__nav-link {
        padding: 0;
        text-decoration: none;

        &::before {
          content: "-";
          margin-right: 5px;
        }
      }

      > .iv2-footer__nav-list {
        display: block;
        margin-bottom: 0;
        margin-right: 0px;
        padding-top: 0;
        padding-left: 0;
        border-left: 0;

        .iv2-footer__nav-item {
          padding-right: 0;
          padding-bottom: 0;
          .iv2-footer__nav-link {
            margin-left: 20px;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }

  .iv2-footer__sns {
    .iv2-footer__sns-link {
      margin-right: 15px;
      font-size: 16px;

      .iv2-footer__sns-icon {
        width: 15px;
      }
    }
  }

  .iv2-footer__hr {
    padding: 0;
  }

  .iv2-footer__bottom {
    .iv2-footer__bottom-item {
      .iv2-language {
        position: relative;

        .iv2-language-btn {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 0;
          border-radius: 0;
          background: transparent;
          font-size: 13px;
        }

        .iv2-language__selector {
          padding: 5px 0px;
          position: absolute;
          top: 0;
          transform: translateY(-110%);
          width: 130px;
          -webkit-appearance: none;
          appearance: none;
          border: 1px solid;
          border-radius: 5px;
          transition: 0.3s;
          opacity: 0;
          visibility: hidden;

          &.active {
            opacity: 1;
            visibility: inherit;
          }

          .iv2-language__selector-list {
            padding: 3px 13px;
            cursor: pointer;

            &.opacity {
              opacity: 0.6;
            }
          }
        }
      }
    }

    .iv2-footer__pagetop {
      cursor: pointer;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-footer {
    padding: 50px 0;
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-footer {
    padding: 30px 0;
  }

  .iv2-footer__container {
    .iv2-footer__hr {
      margin: 10px 0;
    }

    .iv2-footer__bottom {
      .iv2-footer__bottom-item {
        justify-content: center;

        &.translation {
          justify-content: space-between;

          .iv2-language {
            .iv2-language__selector {
              right: 0;
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-footer {
    margin-bottom: 60px;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }

  .iv2-footer__container {
    padding-bottom: 20px;
    .iv2-footer__logo {
      margin: 25px auto;
      text-align: center;
      .iv2-footer__logo-img {
        max-width: 80%;
      }
    }

    .iv2-footer__item {
      text-align: center;
    }

    .iv2-footer__nav {
      display: none;
    }
  }
}
</style>
