var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.items.is_open_blog == 1 &&
    _vm.blog.length > 0 &&
    _vm.params.limit > 0 &&
    _vm.indexOpenType == 1
  )?_c('section',{staticClass:"iv2-section-blog position-relative",attrs:{"id":"blogArticles"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row clearfix"},[_vm._m(0),_vm._l((_vm.limitCount),function(item,index){return _c('div',{key:item.id,class:[
          (index === 0)
            ? 'iv2-top-blog__item mb-3 mb-md-4 col-xl-4 col-12 first'
            : 'iv2-top-blog__item mb-3 mb-md-4 col-xl-4 col-6'
        ]},[_c('NewsCard',{attrs:{"content":item}})],1)})],2)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-40"})])}]

export { render, staticRenderFns }